<template>
  <LandingPageLayout>
    <div class="container">
      <div class="terms-container">
        <h2 class="fs-40 m-fs-36 fw-600 color-black mb-1 mt-5">
          Terms and Conditions
        </h2>
        <p class="meta__intro-description text__nav-grey lh-28">
          By using the site and the services available on it, you are deemed to
          accept these terms and conditions and any additional terms.
        </p>
      </div>
      <div class="divider mt-5" />

      <div class="terms-container mb-5">
        <h2 class="fs-36 m-fs-30 fw-600 color-black mb-1 mt-5">
          1. Use of the Site
        </h2>
        <p class="meta__intro-description text__nav-grey lh-28 mt-2">
          1.1. The information and services available on the site are provided
          for the sole purpose of individuals looking for employment
          opportunities and career information. You may use, print and download
          data from the site for these purposes only. You may not otherwise
          copy, display, transmit or distribute any material from the site.
          However, suppose you do or perform any other unauthorised information
          processing on the site. In that case, it shall be deemed a material
          breach of these terms and conditions, which, in the case of a
          Jobseeker, “You” entitle us to terminate the services immediately
          without prior notice. Further, we reserve the right to suspend the
          provision of the Services to you in circumstances where we reasonably
          believe that you have performed any unauthorised processing of
          information.
          <br />
          <br />
          1.2. All copyright, database rights and other intellectual property
          rights in the site and the material available belong to its
          third-party suppliers or us.
        </p>

        <h2 class="fs-36 m-fs-30 fw-600 color-black mb-1 mt-5">
          2. Responsibility of Recruitment
        </h2>
        <p class="meta__intro-description text__nav-grey lh-28 mt-2">
          2.1. Jobseekers have to be aware that Infohob is a job search engine
          and is not liable for the recruiting process thereafter connecting job
          opportunities to job seekers. This means that we do not:
          <br />
          <br />
        </p>
        <ul class="terms-ul">
          <li>
            <p class="meta__intro-description text__nav-grey lh-28">
              obtain confirmation of the identity of a jobseeker or that they
              have the experience, training, qualifications or authorisation to
              work in the position to be filled or that they wish to undertake
              the role to be filled;
            </p>
          </li>
          <li>
            <p class="meta__intro-description text__nav-grey lh-28">
              take any steps to ensure the jobseekers are aware of any
              requirement imposed by law or otherwise, which must be satisfied
              by either of them to permit the jobseeker to fulfil the position
              to be filled.
            </p>
          </li>
          <li>
            <p class="meta__intro-description text__nav-grey lh-28">
              give any indication to recruiters whether jobseekers are suitable
              for the position they applied for.
            </p>
          </li>
          <li>
            <p class="meta__intro-description text__nav-grey lh-28">
              take up any references about a jobseeker; or,
            </p>
          </li>
          <li>
            <p class="meta__intro-description text__nav-grey lh-28">
              make any arrangements for accommodation of jobseekers.
            </p>
          </li>
        </ul>

        <h2 class="fs-36 m-fs-30 fw-600 color-black mb-1 mt-5">3. CV Search</h2>
        <p class="meta__intro-description text__nav-grey lh-28 mt-2">
          By registering for our services, and if you upload your CV, parts of
          your CV and profile information will be visible on the platform to
          recruiters. We analyse your CV and recognise entities such as
          keywords, such as job titles, companies, location, and skills, and
          create search content for recruiters to find your profile. We will
          also use such information to create a filter and relevancy tab.
        </p>
      </div>
    </div>
  </LandingPageLayout>
</template>

<script>
import LandingPageLayout from "@/layouts/LandingPageLayout";

export default {
  name: "HireTalent",
  components: {
    LandingPageLayout
  },
  data() {
    return {
      formData: {},
      faqs: [
        {
          active: false,
          title: "Non sed lacinia facilisis amet a molestie.",
          description:
            "Pellentesque neque vel amet egestas sit sapien sit risus. Mi at morbi parturient cursus tempus, consequat, phasellus. Dignissim congue sollicitudin nunc odio lectus. Id volutpat."
        },
        {
          active: false,
          title: "Odio nulla etiam enim tellus.",
          description:
            "Pellentesque neque vel amet egestas sit sapien sit risus. Mi at morbi parturient cursus tempus, consequat, phasellus. Dignissim congue sollicitudin nunc odio lectus. Id volutpat."
        },
        {
          active: false,
          title: "Fermentum diam aliquam in lectus adipiscing interdum mi. ",
          description:
            "Pellentesque neque vel amet egestas sit sapien sit risus. Mi at morbi parturient cursus tempus, consequat, phasellus. Dignissim congue sollicitudin nunc odio lectus. Id volutpat."
        },
        {
          active: false,
          title: "Amet justo eu tortor tortor.",
          description:
            "Pellentesque neque vel amet egestas sit sapien sit risus. Mi at morbi parturient cursus tempus, consequat, phasellus. Dignissim congue sollicitudin nunc odio lectus. Id volutpat."
        }
      ]
    };
  }
};
</script>

<style lang="scss">
@use "@/assets/scss/mixins/index.scss" as *;

.terms-container {
  width: 95%;
  max-width: 566px;
}

.terms-ul {
  list-style-type: disc;
  padding-left: 33px;
}
</style>
